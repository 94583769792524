import React, {useState} from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Box,
	Button,
	Input,
	FormControl,
	FormLabel,
	FormErrorMessage,
	VStack,
} from '@tempo/core';
import {validateActivationLinkInput} from '@utils/validators';

interface SendActivationLinkModalProps {
	email?: string;
	isOpen: boolean;
	onClose: () => void;
	onConfirm: (email: string) => void;
}

const SendActivationLinkModal: React.FC<SendActivationLinkModalProps> = ({
	email: initialEmail,
	isOpen,
	onClose,
	onConfirm,
}) => {
	const [email, setEmail] = useState(initialEmail || '');
	const [emailError, setEmailError] = useState<string | null>(null);

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		if (emailError) {
			setEmailError(null);
		}
	};

	const handleConfirm = () => {
		const error = validateActivationLinkInput(email.trim());

		if (error) {
			setEmailError(error);
			return;
		}

		setEmailError(null);
		onConfirm(email);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				padding="spacing.3xl"
				data-testid="send-activation-link-modal"
			>
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
				>
					Send an activation link
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					Send a link to the customer so that he can match his Deezer account
					with his subscription.
				</Text>
				<VStack spacing="spacing.xl" mb="spacing.xl">
					<FormControl isRequired isInvalid={!!emailError}>
						<FormLabel htmlFor="email" color="text.neutral.primary.default">
							Email address
						</FormLabel>
						<Input
							id="email"
							type="email"
							value={email}
							onChange={handleEmailChange}
						/>
						{emailError && (
							<FormErrorMessage data-testid="email-error">
								{emailError}
							</FormErrorMessage>
						)}
					</FormControl>
					<Text
						alignSelf="flex-end"
						color="text.neutral.secondary.default"
						fontSize="var(--Accessibility-14, 14px)"
					>
						<span style={{color: 'var(--tempo-colors-red-500)'}}>* </span>
						<span>Mandatory field</span>
					</Text>
				</VStack>
				<Box display="flex" justifyContent="end">
					<Button variant="ghost" mr="spacing.s" onClick={onClose}>
						Cancel
					</Button>
					<Button onClick={handleConfirm} isDisabled={!email.trim()}>
						Send the email
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};
export default SendActivationLinkModal;
