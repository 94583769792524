import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Box,
	Button,
} from '@tempo/core';
import {CrossCircleFilledIcon} from '@tempo/icons';

interface ErrorModalProps {
	isOpen: boolean;
	onClose: () => void;
	onRetry: () => void;
	title: string;
	subtitle: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
	isOpen,
	onClose,
	onRetry,
	title,
	subtitle,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				padding="spacing.3xl"
				display="flex"
				flexDirection="column"
				alignItems="center"
			>
				<CrossCircleFilledIcon
					color="icon.feedback.error.default"
					boxSize="64px"
					marginBottom="spacing.l"
				/>
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
					data-testid="error-modal-header"
				>
					{title}
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					{subtitle}
				</Text>
				<Box display="flex" gap="spacing.s">
					<Button
						variant="ghost"
						onClick={onClose}
						data-testid="error-modal-close-button"
					>
						Close
					</Button>
					<Button
						mr="spacing.s"
						onClick={onRetry}
						data-testid="error-modal-retry-button"
					>
						Try again
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default ErrorModal;
